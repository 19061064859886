<template>
	<div class="fixed z-50 w-full px-4 pt-6 overflow-hidden bg-white dark:text-white lg:px-8 dark:bg-mx-green-800 isolate">
		<h1 class="text-3xl font-semibold">
			<!-- TODO i18n -->
			Settings
		</h1>
		<div class="text-mx-gray-500 dark:text-mx-gray-400">
			<!-- TODO i18n -->
			Administrative settings for your customer portal application.
		</div>
		<NavSubNav :tabs="tabs" />
	</div>
</template>

<script setup lang="ts">
import { useAuth } from '@/composables/useAuth'
const { hasSettingsViewPermission, hasAlertsViewAllPermission } = useAuth()

const tabs = computed(() => {
	const arr = []
	if (hasSettingsViewPermission) {
		arr.push({ id: 'system', name: 'System', href: '/settings', route: 'internal-settings', isDefault: true })
	}
	if (hasAlertsViewAllPermission) {
		arr.push({ id: 'alert', name: 'Outage/Alert', href: '/settings/alert', route: 'internal-settings-alert' })
	}
	return arr
})

</script>
